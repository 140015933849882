import { render, staticRenderFns } from "./getExchangeCodeSuccess.vue?vue&type=template&id=35ad4014&scoped=true"
import script from "./getExchangeCodeSuccess.vue?vue&type=script&lang=js"
export * from "./getExchangeCodeSuccess.vue?vue&type=script&lang=js"
import style0 from "./getExchangeCodeSuccess.vue?vue&type=style&index=0&id=35ad4014&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ad4014",
  null
  
)

export default component.exports